import { ItemActionTypes as ActionTypes } from './constants';

export const createItemRequest = (data: any) => ({
  type: ActionTypes.CREATE_ITEM_REQUEST,
  payload: {data},
});

export const resetItemSuccessState = () => ({
  type: ActionTypes.RESET_ITEM_SUCCESS_STATE,
});

export const createItemSuccess = (response: any) => ({
  type: ActionTypes.CREATE_ITEM_SUCCESS,
  payload: response,
});

export const createItemFailure = (error: any) => ({
  type: ActionTypes.CREATE_ITEM_FAILURE,
  payload: error,
});

export const resetSingleItem = () => ({
  type: ActionTypes.RESET_SINGLE_ITEM,
});

export const fetchItemsRequest = (page: number, limit: number, sortBy: string,
  sort: string,
  searchBy: string, origin?:string) => ({
  type: ActionTypes.FETCH_ITEMS_REQUEST,
  payload: { page, limit, sortBy, sort, searchBy , origin},
});

export const fetchItemsSuccess = (response: any) => ({
  type: ActionTypes.FETCH_ITEMS_SUCCESS,
  payload: response,
});

export const fetchItemsFailure = (error: any) => ({
  type: ActionTypes.FETCH_ITEMS_FAILURE,
  payload: error,
});

export const fetchItemBomInputsRequest = (id?: number) => ({
  
  type: ActionTypes.FETCH_ITEM_BOM_INPUTS_REQUEST,
  payload: { id },
});

export const fetchItemBomInputsSuccess = (response: any) => ({
  type: ActionTypes.FETCH_ITEM_BOM_INPUTS_SUCCESS,
  payload: response,
});

export const fetchItemBomInputsFailure = (error: any) => ({
  type: ActionTypes.FETCH_ITEM_BOM_INPUTS_FAILURE,
  payload: error,
});

export const fetchItemByIdRequest = (id: number) => ({
  type: ActionTypes.FETCH_ITEM_BY_ID_REQUEST,
  payload: id,
});

export const fetchItemByIdSuccess = (response: any) => ({
  type: ActionTypes.FETCH_ITEM_BY_ID_SUCCESS,
  payload: response,
});

export const fetchItemByIdFailure = (error: any) => ({
  type: ActionTypes.FETCH_ITEM_BY_ID_FAILURE,
  payload: error,
});

export const editItemRequest = (id: number, data: any) => ({
  type: ActionTypes.EDIT_ITEM_REQUEST,
  payload: { id, data },
});

export const editItemSuccess = (response: any) => ({
  type: ActionTypes.EDIT_ITEM_SUCCESS,
  payload: response,
});

export const editItemFailure = (error: any) => ({
  type: ActionTypes.EDIT_ITEM_FAILURE,
  payload: error,
});
