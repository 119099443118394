import { GAApprovalActionTypes as ActionTypes } from './constants';

export const createGAApprovalRequest = (data: any) => ({
  type: ActionTypes.CREATE_GAApproval_REQUEST,
  payload: {data},
});

export const resetGAApprovalSuccessState = () => ({
  type: ActionTypes.RESET_GAApproval_SUCCESS_STATE,
});

export const createGAApprovalSuccess = (response: any) => ({
  type: ActionTypes.CREATE_GAApproval_SUCCESS,
  payload: response,
});

export const createGAApprovalFailure = (error: any) => ({
  type: ActionTypes.CREATE_GAApproval_FAILURE,
  payload: error,
});

export const resetSingleGAApproval = () => ({
  type: ActionTypes.RESET_SINGLE_GAApproval,
});

export const fetchGAApprovalsRequest = (page: number, limit: number, sortBy: string,
  sort: string,
  searchBy: string) => ({
  type: ActionTypes.FETCH_GAApprovalS_REQUEST,
  payload: { page, limit, sortBy, sort, searchBy },
});

export const fetchGAApprovalsSuccess = (response: any) => ({
  type: ActionTypes.FETCH_GAApprovalS_SUCCESS,
  payload: response,
});

export const fetchGAApprovalsFailure = (error: any) => ({
  type: ActionTypes.FETCH_GAApprovalS_FAILURE,
  payload: error,
});

export const fetchGAApprovalByIdRequest = (id: number) => ({
  type: ActionTypes.FETCH_GAApproval_BY_ID_REQUEST,
  payload: id,
});

export const fetchGAApprovalByIdSuccess = (response: any) => ({
  type: ActionTypes.FETCH_GAApproval_BY_ID_SUCCESS,
  payload: response,
});

export const fetchGAApprovalByIdFailure = (error: any) => ({
  type: ActionTypes.FETCH_GAApproval_BY_ID_FAILURE,
  payload: error,
});

export const editGAApprovalRequest = (id: number, data: any) => ({
  type: ActionTypes.EDIT_GAApproval_REQUEST,
  payload: { id, data },
});

export const editGAApprovalSuccess = (response: any) => ({
  type: ActionTypes.EDIT_GAApproval_SUCCESS,
  payload: response,
});

export const editGAApprovalFailure = (error: any) => ({
  type: ActionTypes.EDIT_GAApproval_FAILURE,
  payload: error,
});
