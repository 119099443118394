import { all } from "redux-saga/effects";
import watchEmployee from "./employee/saga";
import { watchEnquiry } from "./enquiry/saga";
import { watchTask } from "./task/saga";
import layoutSaga from "./layout/saga";
import { watchSendOtpRequest, watchVerifyOtpRequest, watchVerifyCustomerSession, watchDeleteUserSession } from "./login/saga";
import watchTeam from "./team/saga";
import watchReport from "./report/saga";
import watchTransporter from "./transporter/saga";
import watchVendor from "./vendor/saga";
import watchProduct from "./product/saga";
import watchForm from "./form/saga";
import watchRole from "./role/saga";
import watchApproval from "./approval/saga";
import watchProjectTask from "./project-management/saga";
import watchCustomer from "./customer/saga";
import watchOrganization from "./organization/saga";
import watchQuery from "./query/saga";
import watchFiles from "./files/saga";
import watchTag from "./tag/saga";
import watchChat from "./chat/saga";
import watchLeads from "./lead/saga";
import watchNotification from "./notification/saga";
import watchMasterJob from "./job-defination/saga";
import watchTrigger from "./trigger/saga";
import watchMachine from "./machine-management/saga";
import watchVisitCount from "./websitevisits/saga";
import watchRawMaterial from "./raw-material/saga";
import watchSupplier from "./supplier/saga";
import watchContact from "./contacts/saga";
import watchLeadsLocator from "./lead-locator/saga";
import watchWarehouse from "./warehouse/saga";
import watchProcesses from "./processes/saga";
import watchZohoVendor from "./zohoVendors/saga";
import watchGateEntrys from "./gate-entry/saga";
import watchGrn from "./grn/saga";
import watchPo from "./po/saga";
import watchQuotations from "./quotation/saga";
import watchRfqs from "./rfq/saga";
import watchIndents from "./indents/saga";
import watchApprovalV2 from "./approvalV2/saga";
import watchItemCategories from "./item-categories/saga";
import watchItems from "./items/saga";
import watchIQCParameter from "./iqc-parameter/saga";
import watchIQCEquipment from "./iqc-equipment/saga";
import watchIQCQuality from "./iqc-quality/saga";
import watchInventory from "./inventory/saga";
import watchCustomerQuotations from "./customer-quotation/saga";
import watchProjectV2s from "./projectV2/saga";
import watchGins from "./gin/saga";
import watchDeals from "./deal/saga";
import watchGADrawings from "./gaDrawing/saga";
import watchFabDrawings from "./fabDrawing/saga";
import watchPayments from "./payment/saga";
import watchDPRs from "./dpr/saga";
import watchCommissionings from "./comissioning/saga";
import watchHandovers from "./handover/saga";
import watchClaims from "./claim/saga";
import watchExpenses from "./expense/saga";
import watchGAApprovals from "./gaApproval/saga";


export default function* rootSaga() {
  yield all([
    watchEnquiry(),
    watchTask(),
    layoutSaga(),
    watchSendOtpRequest(),
    watchVerifyOtpRequest(),
    watchVendor(),
    watchTransporter(),
    watchTeam(),
    watchReport(),
    watchEmployee(),
    watchProduct(),
    watchForm(),
    watchRole(),
    watchApproval(),
    watchProjectTask(),
    watchCustomer(),
    watchOrganization(),
    watchQuery(),
    watchFiles(),
    watchTag(),
    watchChat(),
    watchLeads(),
    watchNotification(),
    watchVerifyCustomerSession(),
    watchMasterJob(),
    watchTrigger(),
    watchMachine(),
    watchVisitCount(),
    watchDeleteUserSession(),
    watchRawMaterial(),
    watchSupplier(),
    watchContact(),
    watchLeadsLocator(),
    watchProcesses(),
    watchIQCQuality(),
    watchIQCParameter(),
    watchIQCEquipment(),
    watchInventory(),
    watchWarehouse(),
    watchZohoVendor(),
    watchGateEntrys(),
    watchGrn(),
    watchPo(),
    watchQuotations(),
    watchGins(),
    watchRfqs(),
    watchClaims(),
    watchExpenses(),
    watchIndents(),
    watchProjectV2s(),
    watchApprovalV2(),
    watchPayments(),
    watchItemCategories(),
    watchItems(),
    watchCustomerQuotations(),
    watchDeals(),
    watchGADrawings(),
    watchFabDrawings(),
    watchDPRs(),
    watchCommissionings(),
    watchHandovers(),
    watchGAApprovals(),
    
  ]);
}
