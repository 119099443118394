import { APICore } from "./apiCore";
const api = new APICore();

function createGAApproval(data: any) {
    const baseUrl = `/gaApproval/`
    return api.create(`${baseUrl}`, { ...data })
}
function fetchGAApprovals(page: any, limit: any, sortBy?: string, sort?: string, searchBy?: string) {
    const baseURL = `/gaApproval/`
    const params = new URLSearchParams();
    if (page !== undefined) params.append('page', page.toString());
    if (limit !== undefined) params.append('limit', limit.toString());
    if (sortBy) params.append('sortBy', sortBy);
    if (sort) params.append('sort', sort);
    if (searchBy) params.append('searchBy', searchBy);
    const url = `${baseURL}?${params.toString()}`;

    return api.get(url, "");
}

function fetchGAApprovalById(id: number) {
    const url = `/gaApproval/${id}`;
    return api.get(url, "");
}

function editGAApproval(id: number, data: any) {
    const url = `/gaApproval/${id}`;
    return api.update(url, data);
}


async function createGAApprovalWithFiles(params: { gaApprovalData: any, imageData?: any }) {
    try {
        const baseUrl = '/gaApproval';

        const createdGAApproval = await api.create(baseUrl, { ...params.gaApprovalData });

        const gaApprovalResponse = createdGAApproval.data;

        const fileUrl = `file/upload`;
        let newImageDataResponse: number[] = [];

        if (params.imageData instanceof FormData) {
            params.imageData.append('uploadedBy', String(105));
            params.imageData.append('type', 'GA Approval');
            params.imageData.append('typeId', String(gaApprovalResponse?.data?.newGA?.id));
            params.imageData.append('tagId', "76");

            const newImageFile = await api.create(fileUrl, params.imageData);
            const newImageFileResponse = newImageFile.data;
            newImageDataResponse = (newImageFileResponse?.data?.response?.fileIds || []).map((id: string) => Number(id));
        }

        if (newImageDataResponse.length > 0) {
            const updatedBody = {
                id: gaApprovalResponse?.data?.newGA?.id,
                body: {
                    ...gaApprovalResponse?.data?.newGA,
                    image_id: newImageDataResponse,
                }
            };

            const baseRfqUrl = `/gaApproval/updateImage/${gaApprovalResponse?.data?.newGA?.id}`;
            const updateRfq = await api.update(baseRfqUrl, { ...updatedBody.body });

            return updateRfq;
        }

        return gaApprovalResponse;

    } catch (error) {
        console.error('Error creating gaApproval with files:', error);
        throw error;
    }
}

async function updateGAApprovalWithFiles(params: { id: number, gaApprovalData: any, imageData?: any }) {
    try {
        const fileUrl = `file/upload`;
        let newImageDataResponse: number[] = [];
        console.log("first", params.imageData)
        if (params.imageData instanceof FormData) {
            params.imageData.append('uploadedBy', String(params.id));
            params.imageData.append('type', 'GA Approval');
            params.imageData.append('typeId', String(params.id));
            params.imageData.append('tagId', "76");

            const newImageFile = await api.create(fileUrl, params.imageData);
            const newImageFileResponse = newImageFile.data;
            newImageDataResponse = (newImageFileResponse?.data?.response?.fileIds || []).map((id: string) => Number(id));
        }

        const combinedImageIds = [
            ...(params.gaApprovalData.image_id || []),
            ...newImageDataResponse
        ];

        const baseUrl = `/gaApproval/${params.id}`;
        return api.update(baseUrl, {
            ...params.gaApprovalData,
            image_id: combinedImageIds
        });
    } catch (error) {
        console.error('Error updating gaApproval with files:', error);
        throw error;
    }
}




export { createGAApproval, fetchGAApprovalById, fetchGAApprovals, editGAApproval, createGAApprovalWithFiles, updateGAApprovalWithFiles };
