/* eslint-disable */
import { put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { GAApprovalActionTypes } from './constants';
import {
  createGAApproval,
  editGAApproval,
  fetchGAApprovalById,
    fetchGAApprovals
} from 'helpers/api/gaApproval';
import {
  createGAApprovalFailure,
  createGAApprovalRequest,
  createGAApprovalSuccess,
  editGAApprovalFailure,
  editGAApprovalRequest,
  editGAApprovalSuccess,
  fetchGAApprovalByIdFailure,
  fetchGAApprovalByIdRequest,
  fetchGAApprovalByIdSuccess,
  fetchGAApprovalsFailure,fetchGAApprovalsRequest,
  fetchGAApprovalsSuccess,
    resetGAApprovalSuccessState,
    resetSingleGAApproval
} from './actions';

function* fetchGAApprovalsSaga(action: any): SagaIterator {
  try {
    const { page, limit, sortBy, sort, searchBy  } = action.payload || {};
    const response = yield call(fetchGAApprovals, page, limit , sortBy, sort, searchBy);
    const grnsData = response.data; 
    yield put(fetchGAApprovalsSuccess(grnsData));
  } catch (e: any) {
    yield put(fetchGAApprovalsFailure(e));
  }
}

function* createGAApprovalSaga(action: any): SagaIterator {
  try {
    const { data } = action.payload;
    const response = yield call(createGAApproval, data);
    const grnData = response.data;
    yield put(createGAApprovalSuccess(grnData));
    yield put({ type: GAApprovalActionTypes.FETCH_GAApprovalS_REQUEST, payload: { page: 1, limit: 15 } });
  } catch (e: any) {
    yield put(createGAApprovalFailure(e));
  }
}

function* fetchGAApprovalByIdSaga(action: any): SagaIterator {
  try {
    // const { id } = action.payload;
    const response = yield call(fetchGAApprovalById, action.payload);
    const grnData = response.data;
    yield put(fetchGAApprovalByIdSuccess(grnData));
  } catch (e: any) {
    yield put(fetchGAApprovalByIdFailure(e));
  }
}

function* editGAApprovalSaga(action: any): SagaIterator {
  try {
    const { id, data } = action.payload;
    const response = yield call(editGAApproval, id, data);
    const grnData = response.data;
    yield put(editGAApprovalSuccess(grnData));
    yield put({ type: GAApprovalActionTypes.FETCH_GAApproval_BY_ID_REQUEST, payload: id });
  } catch (e: any) {
    yield put(editGAApprovalFailure(e));
  }
}

function* watchGAApprovals() {
  yield takeEvery(GAApprovalActionTypes.FETCH_GAApprovalS_REQUEST, fetchGAApprovalsSaga);
  yield takeEvery(GAApprovalActionTypes.CREATE_GAApproval_REQUEST, createGAApprovalSaga);
  yield takeEvery(GAApprovalActionTypes.FETCH_GAApproval_BY_ID_REQUEST, fetchGAApprovalByIdSaga);
  yield takeEvery(GAApprovalActionTypes.EDIT_GAApproval_REQUEST, editGAApprovalSaga);
}

export default watchGAApprovals;
