import { GAApprovalActionTypes } from './constants';

const INIT_STATE = {
  count: 0,
  loading: true,
  gaApproval: [],
  singleGAApproval: {},
  error: {},
  success: false,
};

const GAApprovalReducer = (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case GAApprovalActionTypes.CREATE_GAApproval_REQUEST:
    case GAApprovalActionTypes.EDIT_GAApproval_REQUEST:
    case GAApprovalActionTypes.FETCH_GAApprovalS_REQUEST:
    case GAApprovalActionTypes.FETCH_GAApproval_BY_ID_REQUEST:
    case GAApprovalActionTypes.RESET_GAApproval_SUCCESS_STATE:
      return { ...state, loading: true, error: {}, success: false };

    case GAApprovalActionTypes.SET_SINGLE_GAApproval:
    return { ...state , singleGAApproval: action.payload.data, loading:false }  
    case GAApprovalActionTypes.CREATE_GAApproval_SUCCESS:
      return {
        ...state,
        gaApproval: [...state.gaApproval, action.payload.data],
        loading: false,
        error: {},
        success: true,
      };

    case GAApprovalActionTypes.EDIT_GAApproval_SUCCESS:
      const updatedGAApprovals = state.gaApproval.map((gaApproval: any) =>
        gaApproval.id === action.payload.data.id ? action.payload.data : gaApproval
      );
      return {
        ...state,
        gaApproval: updatedGAApprovals,
        loading: false,
        error: {},
        success: true,
      };

    case GAApprovalActionTypes.FETCH_GAApprovalS_SUCCESS:
      return {
        ...state,
        gaApproval: action.payload.data,
        loading: false,
        error: {},
        success: true,
        count: action.payload.count,
      };

    case GAApprovalActionTypes.FETCH_GAApproval_BY_ID_SUCCESS:
      return {
        ...state,
        singleGAApproval: action.payload.data,
        gaApproval: [action.payload.data],
        loading: false,
        error: {},
        success: true,
      };
    case GAApprovalActionTypes.RESET_SINGLE_GAApproval:
      return {
          ...state,
          singleGAApproval: null,
      };

    case GAApprovalActionTypes.CREATE_GAApproval_FAILURE:
    case GAApprovalActionTypes.EDIT_GAApproval_FAILURE:
    case GAApprovalActionTypes.FETCH_GAApprovalS_FAILURE:
    case GAApprovalActionTypes.FETCH_GAApproval_BY_ID_FAILURE:
      return { ...state, error: action.payload.error, loading: false, success: false };

    default:
      return state;
  }
};

export default GAApprovalReducer;
