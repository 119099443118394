import { APICore } from "./apiCore";
const api = new APICore();

function createItem(data: any) {
    const baseUrl = `/item/`
    return api.create(`${baseUrl}`, { ...data })
}
function fetchItems(page: any, limit: any, sortBy?: string, sort?: string, searchBy?: string, origin?: string) {
    const baseURL = `/item/`
    const params = new URLSearchParams();
    if (page !== undefined) params.append('page', page.toString());
    if (limit !== undefined) params.append('limit', limit.toString());
    if (sortBy) params.append('sortBy', sortBy);
    if (sort) params.append('sort', sort);
    if (searchBy) params.append('searchBy', searchBy);
    if (origin) params.append('origin', origin);
    const url = `${baseURL}?${params.toString()}`;

    return api.get(url, "");
}

function fetchItemById(id: number) {
    const url = `/item/${id}`;
    return api.get(url, "");
}

function editItem(id: number, data: any) {
    const url = `/item/${id}`;
    return api.update(url, data);
}

async function zohoEditItem(params: { id: number, itemData: any }) {
    try {
        const baseURL = `/item/zoho/${params.id}`;
        await api.update(baseURL, { ...params.itemData })

    } catch (error) {
        console.error('Error creating item with files:', error);
        throw error;
    }
}

async function createItemWithZoho(itemBody: any) {
    try {
        const baseUrl = '/item';

        const createdItem = await api.create(baseUrl, itemBody);

        const itemResponse = createdItem.data;

        const itemId = itemResponse?.data?.newItem?.id;
        const newZohoItem = itemResponse?.data?.newZohoItem

        if (newZohoItem && itemResponse?.data?.newZohoItem?.data) {

            const updatedCreatedBody = {
                id: itemId,
                body: {
                    ...itemResponse?.data?.newItem,
                    zohoItemCode: itemResponse?.data?.newZohoItem?.data?.[0]?.details?.id 
                        || itemResponse?.data?.newZohoItem?.data?.data?.[0]?.details?.id
                }
            };
            
            const baseUpdateItemUrl = `/item/${itemId}`;
            const updatedZohoCodeItem = await api.update(baseUpdateItemUrl, { ...updatedCreatedBody.body });

            return updatedZohoCodeItem;
        } else {
            return itemResponse;
        }

    } catch (error) {
        console.error('Error creating item with files:', error);
        throw error;
    }
}

function editItemBomInputs(id: number, data: any) {
    const url = `/bom/item/bom-inputs/${id}`;
    return api.update(url, data);
}

function fetchItemBomInputs(id: number) {
    const url = `/bom/item/bom-inputs/${id}`;
    return api.get(url, "");
}

function fetchItemBomById(id: number) {
    const url = `/bom/item/${id}`;
    return api.get(url, "");
}

function fetchItemBomInputsScrap(id: number) {
    const url = `/bom/item/bom-inputs-scrap/${id}`;
    return api.get(url, "");
}

function editItemInputsScrap(id: number, data: any) {
    const url = `/bom/item/bom-inputs-scrap/${id}`;
    return api.update(url, data);
}

export { createItem, fetchItemById, fetchItems, editItem, createItemWithZoho, zohoEditItem, fetchItemBomInputs, editItemBomInputs, fetchItemBomById, fetchItemBomInputsScrap, editItemInputsScrap };
