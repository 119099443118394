export enum GAApprovalActionTypes {
    CREATE_GAApproval_REQUEST = 'CREATE_GAApproval_REQUEST',
    CREATE_GAApproval_SUCCESS = 'CREATE_GAApproval_SUCCESS',
    CREATE_GAApproval_FAILURE = 'CREATE_GAApproval_FAILURE',
    FETCH_GAApprovalS_REQUEST = 'FETCH_GAApprovalS_REQUEST',
    FETCH_GAApprovalS_SUCCESS = 'FETCH_GAApprovalS_SUCCESS',
    FETCH_GAApprovalS_FAILURE = 'FETCH_GAApprovalS_FAILURE',
    FETCH_GAApproval_BY_ID_REQUEST = 'FETCH_GAApproval_BY_ID_REQUEST',
    FETCH_GAApproval_BY_ID_SUCCESS = 'FETCH_GAApproval_BY_ID_SUCCESS',
    FETCH_GAApproval_BY_ID_FAILURE = 'FETCH_GAApproval_BY_ID_FAILURE',
    EDIT_GAApproval_REQUEST = 'EDIT_GAApproval_REQUEST',
    EDIT_GAApproval_SUCCESS = 'EDIT_GAApproval_SUCCESS',
    EDIT_GAApproval_FAILURE = 'EDIT_GAApproval_FAILURE',
    RESET_GAApproval_SUCCESS_STATE = 'RESET_GAApproval_SUCCESS_STATE',
    FETCH_SINGLE_GAApproval = 'FETCH_SINGLE_GAApproval',
    SET_SINGLE_GAApproval = 'SET_SINGLE_GAApproval',
    RESET_SINGLE_GAApproval = 'RESET_SINGLE_GAApproval'
}
