import { APICore } from "./apiCore";
const api = new APICore();

function createPaymentInvoice(data: any) {
    const baseUrl = `/payment/invoice`
    return api.create(`${baseUrl}`, { ...data })
}

async function createPaymentInvoiceWithFiles(params: { data: any, imageData?: any }) {
    try {
        const baseUrl = `/payment/invoice`
        const createdInvoice = await api.create(`${baseUrl}`, { ...params.data });
        const invoiceResponse = createdInvoice.data;
        const fileUrl = `file/upload`;
        let newImageDataResponse: number[] = [];
        if (params.imageData instanceof FormData) {
            params.imageData.append('uploadedBy', String(105));
            params.imageData.append('type', 'Invoice');
            params.imageData.append('typeId', String(invoiceResponse?.data?.newPayment?.id));
            params.imageData.append('tagId', '73');
            const newImageFile = await api.create(fileUrl, params.imageData);
            const newImageFileResponse = newImageFile.data;
            newImageDataResponse = (newImageFileResponse?.data?.response?.fileIds || []).map((id: string) => Number(id));
        }
        if (newImageDataResponse.length > 0) {
            const updatedBody = {
                id: invoiceResponse?.data?.newPayment?.id,
                body: {
                    ...invoiceResponse?.data?.newPayment,
                    image_id: newImageDataResponse,
                }
            };

            const baseRfqUrl = `/payment/invoice/updateImage/${invoiceResponse?.data?.newPayment?.id}`;
            const updateRfq = await api.update(baseRfqUrl, { ...updatedBody.body });

            return updateRfq;
        }
        return invoiceResponse;
    } catch (error) {
        console.error('Error creating indent with files:', error);
        return error;
    }
}

function createPayment(data: any) {
    const baseUrl = `/payment/payment`
    return api.create(`${baseUrl}`, { ...data })
}

function fetchPayments(id: any) {
    const url = `/payment/list/${id}`;
    return api.get(url, "");
}

function fetchOutgoingPayments(id: any) {
    const url = `/payment/outgoinglist/${id}`;
    return api.get(url, "");
}

function fetchPoPayments() {
    const url = `/payment/polist`;
    return api.get(url, "");
}

function fetchMilestones() {
    const url = `/payment/milestones`;
    return api.get(url, "");
}

function fetchPaymentById(id: number) {
    const url = `/payment/${id}`;
    return api.get(url, "");
}

function editPayment(id: number, data: any) {
    const url = `/payment/${id}`;
    return api.update(url, data);
}

export { createPaymentInvoice, createPayment, fetchPaymentById, fetchPayments, fetchOutgoingPayments, fetchPoPayments, fetchMilestones, editPayment, createPaymentInvoiceWithFiles };
